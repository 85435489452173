import ErrorComponent from 'components/errors/error-component';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { isEmpty } from 'helpers/misc-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sqsService from 'services/sqs-service';
import initialSqs from 'state/sqs/initial-sqs';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { SQSEntity, SQSPayloadEntity } from 'types/sqs-types';

import RecordView from '../record-view';
import { validateSQS } from 'helpers/validation/sqs-helper';

const SQSCreate = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { sqs_id } = useParams<{ sqs_id: string }>();

  const [sqs, setSqs] = useState<SQSEntity>(initialSqs);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setRouteName } = useRouteName();

  const [validation, setValidation] = useState<ErrorValidation>({});

  const handleChange = (e: InputChangeEvent): void => {
    setSqs(Object.assign({}, sqs, { [e.target.name]: e.target.value }));
  };

  const loadSqs = async (sqs_id: string): Promise<void> => {
    const sqs = await sqsService.get(sqs_id);

    if (sqs.isError) {
      setErrorMessage(sqs.errorMessage.message);
      return;
    }

    setSqs(sqs.data);
  };

  const handleSubmit = async (): Promise<void> => {
    const { status, errors } = validateSQS(sqs);
    let response;

    setValidation(errors);

    if (!status) return;

    setIsLoading(true);

    const requestBody: SQSPayloadEntity = {
      name: sqs.name,
      queue: sqs.queue,
      origin: sqs.origin,
      status: sqs.status,
      message_id: sqs.message_id,
      receipt_handle: sqs.receipt_handle,
      message: sqs.message
    };

    if (sqs_id) {
      response = await sqsService.update(sqs_id, requestBody);
    } else {
      response = await sqsService.create(requestBody);
    }

    setIsLoading(false);
    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      window.history.back();
    }
  };

  useEffect(() => {
    if (sqs_id !== undefined) loadSqs(sqs_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              sqs={sqs}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default SQSCreate;
